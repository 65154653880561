<template>
  <div
    class="d-flex align-center justify-space-between mb-0 activity-filters-wrapper"
  >
    <v-tabs height="60" :show-arrows="!isMobileOrTablet" center-active>
      <v-tab
        v-for="(filter, filterIndex) in formattedFilters"
        :ripple="false"
        :key="filterIndex"
        @change="getActivities(filter)"
      >
        {{ filter.filterGroupName }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    isMobileOrTablet: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
    direction: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      selectValue: {
        text: "Past 30 days",
        value: 30,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "wallet/user",
      currentDateFilter: "wallet/currentDateFilter",
    }),
    formattedFilters() {
      return [
        {
          filterGroupId: 0,
          filterGroupName: "All Activity",
          isIncome: this.direction === "income" ? true : false,
          isOutgoing: this.direction === "income" ? false : true,
        },
        ...this.filters,
      ];
    },
  },
  methods: {
    getActivities(filter) {
      let dateFrom = new Date();

      if (isNaN(this.currentDateFilter)) {
        dateFrom = "";
      } else {
        dateFrom.setDate(dateFrom.getDate() - this.currentDateFilter);
      }
      this.$store.dispatch("wallet/getUserActivity", {
        filter:
          filter.filterGroupName === "All Activity"
            ? ""
            : filter.filterGroupName,
        direction: this.direction,
        skip: 0,
        limit: 10,
        dateFrom: dateFrom.toLocaleString("en-US"),
        dateTo: new Date().toLocaleString("en-US"),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.activity-filters-wrapper {
  max-width: 100%;
}
.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)::v-deep
  .v-btn__content {
  opacity: 1;
}
.v-tabs {
  &.theme--dark {
    ::v-deep .v-tabs-bar {
      background-color: var(--v-appBck-base);
      .v-tabs-bar__content {
        .v-tab {
          &.v-tab--active {
            box-shadow: $box-shadow-dark;
          }
        }
      }
    }
  }
  ::v-deep .v-tabs-slider-wrapper {
    display: none;
  }
  ::v-deep .v-item-group {
    .v-icon.v-icon {
      font-size: 20px;
      color: var(--v-primary-base);
    }
  }
  ::v-deep .v-slide-group {
    .v-slide-group__content {
      align-items: center;
    }
    .v-slide-group__next,
    .v-slide-group__prev {
      flex: 0 1 20px;
      min-width: 20px;
    }
    .v-slide-group__prev {
      justify-content: flex-start;
    }
    .v-slide-group__next {
      justify-content: flex-end;
    }
  }

  ::v-deep .v-tabs-bar__content {
    .v-tab {
      text-transform: none;
      letter-spacing: 0;
      border-radius: $radius-md;
      height: 40px;
      padding: 0px 14px 1px 12px;
      margin: 0 2px;
      font-size: 14px;
      &.v-tab--active {
        color: var(--v-primary-base);
        font-weight: 600;
        box-shadow: $box-shadow-light;
      }
      &::before {
        background-color: transparent;
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .v-tabs {
    ::v-deep .v-slide-group {
      height: 50px;
      .v-slide-group__next,
      .v-slide-group__prev {
        display: none !important;
      }
    }

    ::v-deep .v-tabs-bar__content {
      .v-tab {
        height: 30px;
        font-size: 12px;
      }
    }
  }
}
</style>
